import CrowdSaleModal from 'components/CrowdSale/CrowdSaleModal'
import { SwitchTownNetwork } from 'components/SwitchNetwork/SwitchTownNetwork'
import TokenExchangeModal from 'components/TokenExchange/TokenExchangeModal'
import TwinVaultModal from 'components/TwinVault/TwinVaultModal'
import Web3Status from 'components/Web3Status'
import { TMenuOption } from 'constants/settings/types/TMenuOption'
import { EOptionType } from 'constants/settings/types/TNpcs'
import { AccountElement } from 'game/component/Footer/Footer.style'
import { useActiveWeb3React } from 'hooks'
import Farms from 'pages/Farms'
import Pool from 'pages/Pool'
import PoolFinder from 'pages/PoolFinder'
import Swap from 'pages/Swap'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import React, { useState, useEffect, useRef } from 'react'
import { useSpring } from 'react-spring'
import {
  MenuWrapper,
  ScrollableNavContainer,
  NavLinks,
  NavLinkLi,
  NavLink,
  HamburgerIconContainer,
  HamburgerIcon,
  MobileMenu,
  CloseButton,
  NavWalletLink,
  NavMobileWalletLink
} from './GlobalMenu.styles'
import MenuModal from './MenuModal'

interface GlobalMenuProps {
  menuType: 'landing' | 'game'
}
export function GlobalMenu({ menuType }: GlobalMenuProps) {
  const { account } = useActiveWeb3React()
  const { menuOptions } = useAppConstant()
  const scrollableNavRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [activeComponent, setActiveComponent] = useState<React.ReactNode>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  const slideInStyles = useSpring({
    transform: isOpen ? 'translateX(0%)' : 'translateX(-100%)',
    config: { tension: 220, friction: 20 }
  })

  const onClose = () => setActiveComponent(null)

  const renderOptionComponent = (item: TMenuOption) => {
    switch (item?.dappData.type) {
      case EOptionType.FARM:
        return <Farms dappData={item.dappData} />
      case EOptionType.SWAP:
        return <Swap dappData={item.dappData} />
      case EOptionType.POOLS:
        return <Pool dappData={item.dappData} />
      case EOptionType.IMPORT_POOLS:
        return <PoolFinder dappData={item.dappData} />
      case EOptionType.EXTERNAL_LINK:
        window.open(item.dappData?.externalLinkUrl ?? '', '_blank')
        return null
      case EOptionType.TOKEN_EXCHANGE:
        return <TokenExchangeModal dappData={item.dappData} />
      case EOptionType.CROWDSALE:
        return <CrowdSaleModal dappData={item.dappData} />
      case EOptionType.TWIN_VAULTS:
        return <TwinVaultModal dappData={item.dappData} />
      default:
        return null
    }
  }

  const handleMenuClick = (item: TMenuOption) => {
    setActiveComponent(renderOptionComponent(item))
  }

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    const urlHash = window.location.hash // Example: "#/?swapToken=0x"
    const params = new URLSearchParams(urlHash.substring(2)) // Remove "#/?"

    const swapToken = params.get('swapToken')
    if (swapToken) {
      console.log('Detected swapToken:', swapToken)
      // 🔎 Find the menu option for swapping (assuming it's "SWAP")
      const swapOption = menuOptions.find(option => option.dappData.type === EOptionType.SWAP)

      if (swapOption) {
        const newSwapOption = {
          ...swapOption,
          chainId: 25,
          dappData: { ...swapOption.dappData, preloadSwapForAddress: swapToken }
        }
        console.log(swapToken, newSwapOption)
        handleMenuClick(newSwapOption)
      }
    }
  }, [menuOptions])

  return (
    <>
      <MenuWrapper menuType={menuType}>
        <ScrollableNavContainer ref={scrollableNavRef}>
          <NavLinks menuType={menuType}>
            <NavLinkLi>
              <NavLink href={'/#/'}>Home</NavLink>
            </NavLinkLi>
            {menuType === 'landing' && (
              <NavLinkLi>
                <NavLink href={'/#/game'}>Play</NavLink>
              </NavLinkLi>
            )}
            {menuOptions.map((item, index) => (
              <NavLinkLi key={index}>
                <NavLink href="#" onClick={() => handleMenuClick(item)}>
                  {item.menuLabel}
                </NavLink>
              </NavLinkLi>
            ))}
          </NavLinks>
          <NavWalletLink menuType={menuType}>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              <SwitchTownNetwork />
              <Web3Status />
            </AccountElement>
          </NavWalletLink>
        </ScrollableNavContainer>
        <HamburgerIconContainer onClick={() => setIsOpen(!isOpen)}>
          <HamburgerIcon className={isOpen ? 'open' : ''} menuType={menuType} />
        </HamburgerIconContainer>

        <MenuModal isOpen={!!activeComponent} onClose={onClose}>
          {activeComponent}
        </MenuModal>
      </MenuWrapper>
      <MobileMenu style={slideInStyles} ref={menuRef}>
        <CloseButton onClick={() => setIsOpen(false)}>×</CloseButton>
        <ul>
          <NavLinkLi>
            <NavLink href={'/#/'} onClick={() => setIsOpen(false)}>
              Home
            </NavLink>
          </NavLinkLi>
          {menuType === 'landing' && (
            <NavLinkLi>
              <NavLink href={'/#/game'} onClick={() => setIsOpen(false)}>
                Play
              </NavLink>
            </NavLinkLi>
          )}
          {menuOptions.map((item, index) => (
            <NavLinkLi key={index}>
              <NavLink href="#" onClick={() => handleMenuClick(item)}>
                {item.menuLabel}
              </NavLink>
            </NavLinkLi>
          ))}
          <NavMobileWalletLink>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              <SwitchTownNetwork />
            </AccountElement>
          </NavMobileWalletLink>
        </ul>
      </MobileMenu>
    </>
  )
}
